import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadKoel = async () => {
    if (!url) {
      setMessage('URL is required.');
      return;
    }
    setIsDownloading(true);
    setMessage('');
    try {
      const response = await axios.post('https://ydl.family13.fr/api/download-koel', { url }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage('Téléchargement pour KOEL terminé : ' + response.data.message);
    } catch (error) {
      setMessage('Error: ' + error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">YouTube Music Downloader</h1>
        <div class="App-subtitle">By TFR</div>
        <center><input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter YouTube URL"
          required
          className="input-url"
        /></center>
        <div className="button-group">
          <button onClick={handleDownloadKoel} disabled={isDownloading} className={`button-download ${isDownloading ? 'downloading' : ''}`}>
            {isDownloading ? 'Téléchargement en cours...' : 'Télécharger pour KOEL'}
          </button>
        </div>
        {message && <p>{message}</p>}
      </header>
    </div>
  );
}

export default App;

